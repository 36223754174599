import './Services.css';
import main1 from '../../../img/Services/clean_sofa4.jpg';
import main4 from '../../../img/Services/clean_matrac.jpg';
import main3 from '../../../img/Services/clean_pillow.jpg';
import main2 from '../../../img/Services/clean-chair2.png';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title main-services">
          <h1>
            <span className="main-services-h1span">Profesjonalnie Pranie </span>
            kanap, foteli, materacy, wózków, zasłon, rolet, wykładzin, dywanów,
            tapicerka samochodowa...
          </h1>
          <p className="main-title-upper">
            <span>
              GWARANTUJEMY ZWROT 100% OPŁATY, JEŚLI KLIENT NIE BĘDZIE ZADOWOLONY
              Z EFEKTU PRANIA !!!
            </span>
          </p>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>PRANIE MATERACY</h2>
            <p className="main-services-price">od 100 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Pranie poduszek</h2>
            <p className="main-services-price">od 5 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Pranie foteli</h2>
            <p className="main-services-price">od 15 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Pranie kanap</h2>
            <p className="main-services-price">od 100 zł</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/price">Szczegółowo...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
