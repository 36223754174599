import { MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './Email.css';

function Email() {
  return (
    <div className="email">
      <Link to={'mailto:100pbrud@gmail.com'}>
        <p className="email__link">
          <MdOutlineEmail /> 100pbrud@gmail.com
        </p>
      </Link>
    </div>
  );
}
export default Email;
