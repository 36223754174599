import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/profile.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Dlaczego właśnie my!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Jakość</h3>
          <p>
            Korzystamy z najnowocześniejszego sprzętu i sprawdzonych metod, aby
            osiągnąć maksymalną czystość.
          </p>
        </div>
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Profesjonalizm</h3>
          <p>
            Nasi wysoko wykwalifikowani specjaliści są gwarancją perfekcyjnych
            rezultatów.
          </p>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Indywidualne podejście</h3>
          <p>Uwzględniamy indywidualne potrzeby i wymagania każdego klienta</p>
        </div>
      </div>
    </div>
  );
}
export default Important;
