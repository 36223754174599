import './Lang.css';

function Lang({ lang = 'lang', marginTop = '', children }) {
  return (
    <div style={{ marginTop }} className={lang}>
      {children}
    </div>
  );
}

export default Lang;
