import { NavLink } from 'react-router-dom';
import { useProject } from '../../../tools/ProviderContext';
// import css from './Nav.module.css';
import './Nav.css';
import './Nav.css';
function Nav() {
  const { closeBurgerMenu } = useProject();
  return (
    <>
      <nav className="menu">
        <ul className={'menu__list'}>
          <li className={'menu__item'}>
            <NavLink
              to="/en/main"
              className={({ isActive }) =>
                isActive ? 'menu__link active' : 'menu__link'
              }
              onClick={closeBurgerMenu}
            >
              Main
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/en/about"
              onClick={closeBurgerMenu}
              className="menu__link"
            >
              About Us
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/en/price"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Price
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/en/article1"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Articles
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/en/contact"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}
export default Nav;
