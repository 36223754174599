import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/en/price">EN</Link>
            ) : (
              <Link to="/price">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

export function PriceTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Cennik</h1>
        </div>
        <div className="outsidewidthtable">
          <table className="table">
            <tbody>
              <tr>
                <th className="thleft">Nazwa usług</th>
                <th>Koszt od (zl)</th>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  CENNIK PRANIA TAPICEREK MEBLOWYCH:
                </td>
              </tr>

              <tr>
                <td>pranie kanap</td>
                <td className="center"> 100</td>
              </tr>
              <tr>
                <td>pranie i czyszczenie tapicerki samochodowej</td>
                <td className="center"> 200</td>
              </tr>
              <tr>
                <td>pranie sof</td>
                <td className="center"> 100</td>
              </tr>
              <tr>
                <td>pranie narożników </td>
                <td className="center">150</td>
              </tr>

              <tr>
                <td>pranie tapczanów</td>
                <td className="center">100</td>
              </tr>
              <tr>
                <td>pranie kompletów wypoczynkowych</td>
                <td className="center"> 150</td>
              </tr>
              <tr>
                <td>pranie wersalek</td>
                <td className="center">80</td>
              </tr>
              <tr>
                <td>pranie foteli</td>
                <td className="center">15</td>
              </tr>
              <tr>
                <td>pranie krzeseł</td>
                <td className="center"> 10</td>
              </tr>
              <tr>
                <td>pranie poduszek</td>
                <td className="center"> 5</td>
              </tr>

              <tr>
                <td> pranie materaców {<br />} ( 1 strona ) </td>
                <td className="center"> 100</td>
              </tr>
              <tr>
                <td>pranie fotelików samochodowych dla dzieci</td>
                <td className="center"> 50</td>
              </tr>
              <tr>
                <td>pranie gondoli wózków dziecięcych</td>
                <td className="center"> 50</td>
              </tr>
              <tr>
                <td>pranie pluszaków</td>
                <td className="center"> 10</td>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  CENNIK PRANIA WYKŁADZIN DYWANÓW:
                </td>
              </tr>

              <tr>
                <td>pranie dywanów (sztucznych)</td>
                <td className="center"> 20 zł/m2</td>
              </tr>
              <tr>
                <td>pranie dywanów (wełnianych)</td>
                <td className="center"> 20 zł/m2</td>
              </tr>
              <tr>
                <td>pranie wykładzin dywanowych w domach</td>
                <td className="center"> 10 zł/m2</td>
              </tr>

              <tr>
                <td>pranie wykładzin dywanowych w biurach</td>
                <td className="center font">
                  cena do uzgodnienia w zależności od wielkości
                </td>
              </tr>
              <tr>
                <td>pranie wykładzin w przedszkolach</td>
                <td className="center font">
                  cena do uzgodnienia w zależności od wielkości
                </td>
              </tr>
            </tbody>
          </table>

          <p className="lastquestion">
            <span>
              Proszę, zauważyć, że podane ceny są jedynie orientacyjne i mogą
              się różnić w zależności od indywidualnych potrzeb i warunków
              każdego klienta. Dążymy do zapewnienia naszym klientom najlepszych
              usług, dlatego też cenę dostosowujemy do konkretnych wymagań i
              okoliczności. Cenimy indywidualne podejście oraz staranność w
              zaspokajaniu potrzeb naszych klientów. Dziękujemy za zrozumienie!
            </span>
            {/* <span>Zapraszamy do skorzystania z naszych usług!</span> */}
          </p>
        </div>
      </div>
    </>
  );
}

function Price() {
  return (
    <div className="body_wrapper">
      <Header />

      <ButtomsLang />
      <PriceTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Price;
