import WhatsApp from '../../WhatsUp/WhatsappLink';
import Header from '../1_Header/Container/Header';
import ButtomsLang from '../1_Header/Lang/Lang';
import Main from '../2_Main/Container/Main';
import Footer from '../3_Footer/Footer';

import css from './AllProject.module.css';
function AllProject() {
  return (
    <>
      <div className={css.body_wrapper}>
        <Header />
        <ButtomsLang />
        <Main />

        <WhatsApp />
        <Footer />
      </div>
    </>
  );
}
export default AllProject;
