import './Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import { useProject } from '../../../tools/ProviderContext';

function ButtomsLang() {
  const { isLang, changeLang, setLang } = useProject();

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="-15px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={() => setLang(false)} isLang={isLang}>
            {isLang ? <Link to="/">PL</Link> : <Link to="/">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

export default ButtomsLang;

/* <div className="lang"> */

/* <button onClick={changeLang} className="select">
          <span>RU</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        {/* <button className={isLang ? 'ee' : 'hidden'}>
          {isLang ? <Link to="/ee">Eesti</Link> : <Link to="/">RU</Link>}
        </button> */

/* </div> */
