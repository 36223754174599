import { Link } from 'react-router-dom';
import Img_phone from '../../../img/links/telephone-call.png';
import { FiPhoneCall } from 'react-icons/fi';
import './LinkPhone.css';

function Phone() {
  return (
    <div className="header__phone">
      <Link to={'tel:+48669282433'} className="header__phone-link">
        <img
          title="phone"
          src={Img_phone}
          alt="phone"
          className="header__phone-img"
        />
      </Link>

      <Link to={'tel:+48669282433'} className="">
        <h3 className="header-phone-item">+48-669-282-433</h3>
      </Link>
    </div>
  );
}
export function PhoneIcons() {
  return (
    <div className="header__phone">
      <Link to={'tel:+48669282433'} className="FiPhoneCall">
        <FiPhoneCall />
      </Link>

      <Link to={'tel:+48669282433'} className="">
        <h3 className="header-phone-item">+48-669-282-433</h3>
      </Link>
    </div>
  );
}
export function PhoneOnly() {
  return (
    <div className="">
      <Link to={'tel:+48669282433'} className="">
        <p className="header__phone-only">+48-669-282-433</p>
      </Link>
    </div>
  );
}

export default Phone;
