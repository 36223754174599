import './Buttons.css';

function ButtonLang({ children, isLang, closeLang }) {
  return (
    <button onClick={closeLang} className={isLang ? 'ee' : 'hidden'}>
      {children}
    </button>
  );
}

function Button({ children, select = 'none', onClick }) {
  return (
    <button onClick={onClick} className={select}>
      {children}
    </button>
  );
}

export { ButtonLang, Button };
