import './Services.css';
import main1 from '../../../img/Services/clean_sofa4.jpg';
import main4 from '../../../img/Services/clean_matrac.jpg';
import main3 from '../../../img/Services/clean_pillow.jpg';
import main2 from '../../../img/Services/clean-chair2.png';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title main-services">
          <h1>
            <span className="main-services-h1span">Professionally Laundry</span>
            sofas, armchairs, mattresses, strollers, curtains, blinds, carpets,
            car upholstery...
          </h1>
          <p className="main-title-upper">
            <span>
              WE GUARANTEE A 100% REFUND IF THE CUSTOMER IS NOT SATISFIED WITH
              THE WASHING EFFECT!!!
            </span>
          </p>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Washing couches</h2>
            <p className="main-services-price">od 100 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Washing armchairs</h2>
            <p className="main-services-price">od 15 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Washing pillows</h2>
            <p className="main-services-price">od 5 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>WASHING MATTRESS</h2>
            <p className="main-services-price">od 100 pln</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/en/price">More details...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
