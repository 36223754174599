import { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

import LinkSocial from '../1_Header/SocialLinks/LinkSocial';

import './Footer.css';
import { PhoneOnly } from '../../components/1_Header/LinkPhone/LinkPone';
import Email from '../../components/1_Header/Email/Email';

function FooterMenu() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <nav className="footer-menu">
      <ul className="footer__menu__list">
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/main"
            className="footer__menu__link"
          >
            Main
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/about"
            className="footer__menu__link"
          >
            About us
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/price"
            className="footer__menu__link"
          >
            Price
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/article1"
            className="footer__menu__link"
          >
            Articles
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/contact"
            className="footer__menu__link"
          >
            Contacts
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function Footer() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 490) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="footer">
        <div className="container footer__container">
          <div className="footer__main">
            <div className="footer__menu">
              <FooterMenu />
            </div>

            {isHeader ? (
              <>
                <div className="footer__title_phone"></div>
                <div className="footer__social-links">
                  <LinkSocial />
                  <div className="footer-phone header__info">
                    <PhoneOnly />
                    <Email />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="footer__title_phone">
                  <div className="footer-phone header__info">
                    <PhoneOnly />
                    <Email />
                  </div>
                </div>
                <div className="footer__social-links">
                  <LinkSocial />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="footer__down">
          <h3> Upholstered furniture | SmellClean | 2024 </h3>
        </div>
      </div>
    </>
  );
}
export default Footer;
