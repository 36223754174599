import Articles from '../Articles/Article';
import Services from '../Services/Services';
import Important from '../Important/Important';
import './Main.css';
import TitleGallary from '../Gallary/SWGallary';

function Main() {
  return (
    <main>
      <div className="main-second main-first">
        <div className="container main-container">
          <Services />
        </div>
      </div>
      <div className="main-first main-important">
        <div className="container main__important">
          <Important />
        </div>
      </div>
      <div className="main-first main-important">
        <div className="container main__important">
          <TitleGallary />
        </div>
      </div>
      <div className="main-second main-first">
        <div className="container main-container">
          <Articles />
        </div>
      </div>
    </main>
  );
}
export default Main;
