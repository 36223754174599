import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(false);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={closeLang} isLang={isLang}>
            {isLang ? (
              <Link to="/en/about">EN</Link>
            ) : (
              <Link to="/about">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>O nas</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>
              Cześć, drodzy klienci, jesteśmy firmą sprzątającą "100P-BRUD"!
            </h2>
            <p>
              Firma <strong>"100P-BRUD"</strong> zajmuje niezwykle ważną pozycję
              na rynku, gdyż oferuje usługi niezbędne w życiu codziennym.
              Czyszczenie mebli w domu jest trudnym zadaniem, szczególnie jeśli
              jest w nich dużo brudu. Dlatego warto skorzystać z usług
              profesjonalistów, którzy wiedzą, jak obchodzić się z każdym
              rodzajem tapicerki i zadbać o ich prawidłowe czyszczenie.
            </p>
            <p>
              Nasza firma specjalizuje się w profesjonalnym czyszczeniu
              tapicerki, w tym sof, krzeseł, foteli, łóżek, zasłon, materacy,
              wykładzin, dywanów, wnętrz samochodów. Nasze usługi kierujemy
              zarówno do klientów indywidualnych, jak i firm, które potrzebują
              usługi czyszczenia mebli w biurach, hotelach, restauracjach i
              innych miejscach użyteczności publicznej.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              Głównym celem "100P-BRUD" jest zapewnienie maksymalnej czystości i
              komfortu w lokalach swoich klientów. W tym celu firma wykorzystuje
              wyłącznie wysokiej jakości, bezpieczne dla zdrowia detergenty oraz
              nowoczesny sprzęt, który pozwala na wydajną i szybką pracę.
            </p>
            <p>
              Nasza firma oferuje szeroki zakres usług takich jak czyszczenie
              powierzchni, usuwanie plam, dezodoryzacja, dezynfekcja, odkurzanie
              i inne. Każdą usługę dopasowujemy do indywidualnych potrzeb
              Klienta, tak aby zapewnić jak najlepszy efekt.
            </p>
            <p>
              Firma "100P-BRUD" wyróżnia się elastycznością i możliwością
              dostosowania się do potrzeb klienta. Firma współpracuje zarówno z
              osobami prywatnymi, jak i dużymi przedsiębiorstwami, oferując
              indywidualne rozwiązania dla każdego klienta. Dzięki temu klienci
              mogą być pewni, że ich wymagania zostaną uwzględnione, a praca
              zostanie wykonana na najwyższym poziomie.
            </p>
            <p>
              Jako firma zajmująca się czyszczeniem mebli dokładamy wszelkich
              starań, aby nasi klienci byli czyste, bezpieczne i zadowoleni z
              naszych usług. Firma "100P-BRUD" to niezawodny partner, który
              zawsze służy pomocą i dba o idealną czystość w Twoim domu lub
              biurze.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />

        <ButtomsLang />
        <WhatsApp />
        <AboutTable />
        <Footer />
      </div>
    </>
  );
}
export default About;
