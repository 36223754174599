import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import LinkSocial from '../../1_Header/SocialLinks/LinkSocial';
import foto from '../../../img/contact/plan.jpg';
import './Contact.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/en/contact">EN</Link>
            ) : (
              <Link to="/contact">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>KONTAKT</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Numer telefonu:</p>
            <Link to={'tel:+48669282433'}>
              <h2 className="header-phone-item">+48 669282433</h2>
            </Link>
          </div>
          <div className="contacts__item-phone">
            <p>Email:</p>
            <Link to={'mailto:100pbrud@gmail.com'}>
              <h2 className="header-phone-item">100pbrud@gmail.com</h2>
            </Link>
          </div>

          <div className="contacts__item-social">
            <p>Jesteśmy w sieciach społecznościowych:</p>
            <div className="contacts__item-social-links">
              <LinkSocial />
            </div>
          </div>
          <div className="contacts__item-praca">
            <h2>Plan pracy</h2>
            <img src={foto} alt="foto" />
            <p>Pracujemy 7 dni w tygodniu, w godzinach 6:00-22:00,</p>
            <p>a jeżeli wymaga tego sytuacja również w nocy.</p>
            <p>Działamy na terenie Wodzisław Śląski i okolic.</p>
          </div>
          <div className="adress">Wodzisław Śląski, Polska</div>
        </div>
      </div>
    </div>
  );
}
function Contact() {
  return (
    <div className="body_wrapper">
      <Header />

      <ButtomsLang />
      <ContactTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Contact;
