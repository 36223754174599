import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article1.jpg';
import quilon2 from '../../../img/articles/article1.2.jpg';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/article3">PL</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}
export function Cataloge() {
  const [isScroll, setScroll] = useState(true);

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };
  const toggle2 = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Catalog</h3>
      <ul>
        <li onClick={toggle}>
          <NavLink to="/en/article1">
            <span>Washing mattresses</span>
          </NavLink>
        </li>
        <li onClick={toggle2}>
          <NavLink to="/en/article2">
            <span>Washing carpets and rugs</span>
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/article3">Washing upholstered furniture</NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Washing upholstered furniture Wodzisław Śląski</h2>

            <p>
              Cleaning upholstered furniture is one of the most important
              hygiene activities in our homes and workplaces. Upholstered
              furniture is not only a place to relax, but often also serves as a
              work or dining area. Therefore, they accumulate a lot of dirt,
              bacteria and mites that may have a negative impact on our health.
            </p>
            <p>
              One of the most important reasons for regularly washing
              upholstered furniture is to remove dirt from it. Upholstered
              furniture, especially in light colors, quickly starts to look
              dirty and damaged. Stains such as food stains, oil stains or dried
              food remains are very difficult to remove from the upholstery
              surface. Regular washing will help remove these stains and restore
              the furniture to its original appearance.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Another important reason for washing upholstered furniture is to
              prevent the growth of bacteria and fungi. If dirt and moisture get
              on the surface of upholstered furniture, fungi and bacteria may
              multiply, which may cause diseases and allergies. Washing
              upholstered furniture helps remove these microorganisms, which in
              turn has a positive effect on our health.
            </p>
            <p>
              The third reason for washing upholstered furniture is to get rid
              of dust mites. Dust mites are small organisms that live in dust
              and dirt on furniture surfaces. They cause allergies in many
              people, especially those who have breathing problems. Regularly
              washing upholstered furniture will help get rid of dust mites,
              which in turn will improve the air quality in our homes.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              To sum up, washing upholstered furniture is important to maintain
              hygiene in our homes and workplaces. It helps remove dirt,
              bacteria and mites that can negatively affect our health. Regular
              washing of upholstered furniture allows you to maintain its
              appearance and quality for a long time.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1Ru() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article1Ru;
